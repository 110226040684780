<template>
  <v-form
    v-model="isValidData"
    @submit="submit"
  >
    <v-card-text>
      <v-container>
        <!-- {{ disabledSave }} -->
        <!-- {{ getCantbySelectProduct }} -->
        <!-- <pre>{{ warehousesInventory }}</pre> -->
        {{ operation }}
        <v-sheet elevation="0">
          <v-row class="mx-4">
            <!-- <v-col
              cols="12"
              align-self="start"
            >
              <p class="font-weight-thin text-right">
                Valor total de la entrada
              </p>
              <p
                class="font-weight-black text-right green--text"
                :style="{
                  fontSize: '30px'
                }"
              >
                + 1000$
              </p>
            </v-col> -->

            <!-- <pre>{{ productSelected }}</pre> -->
            <!-- {{ total }} -->
            <v-col
              cols="12"
            >
              <span
                v-if="alert"
                class="red--text"
              >
                La Concesion no puede ser mayor a disponibilidad del producto
              </span>
            </v-col>
            <v-col
              cols="12"
            >
              <v-autocomplete
                v-model="data.relatedWarehouseId"
                label="Tienda"
                :loading="fetchingStoreWarehouses"
                :disabled="fetchingStoreWarehouses"
                :items="storeWarehouses"
                item-text="business"
                item-value="warehouseId"
                no-data-text="No hay coincidencia"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <!-- <v-select
                label="Producto"
                :items="products"
                outlined
              /> -->
              <!-- <pre>{{ JSON.stringify(getProducts, null, 2) }} </pre> -->
              <v-autocomplete
                v-model="operation"
                label="Operación"
                :loading="fetchingProducts"
                :disabled="fetchingProducts"
                :items="typesOperation"
                no-data-text="No hay coincidencia"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <!-- <v-select
                label="Producto"
                :items="products"
                outlined
              /> -->
              <!-- <pre>{{ JSON.stringify(getProducts, null, 2) }} </pre> -->
              <v-autocomplete
                v-model="productSelected"
                label="Productos"
                :loading="fetchingProducts"
                :disabled="fetchingProducts"
                :items="getProducts"
                item-text="name"
                no-data-text="No hay coincidencia"
                return-object
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <template>
                <v-text-field
                  v-model="getCantPackingBySelectProduct"
                  type="number"
                  outlined
                  :loading="fechingWarehouseInventory"
                  label="Rollos disponibles"
                  disabled
                />
              </template>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <template>
                <v-text-field
                  v-model="getCantbySelectProduct"
                  type="number"
                  outlined
                  :loading="fechingWarehouseInventory"
                  label="KG disponibles"
                  disabled
                />
              </template>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="packingCant"
                type="number"
                :max="getCantPackingBySelectProduct"
                min="1"
                :rules="requiredFieldRules('Cantidad es requerido')"
                outlined
                label="Rollos"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="cant"
                type="number"
                min="1"
                :max="getCantbySelectProduct"
                :rules="requiredFieldRules('Cantidad es requerido')"
                outlined
                label="KG"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                class="mx-2"
                fab
                dark
                color="secondary"
                @click="addProductList"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                class="elevation-1"
                no-data-text="No hay productos cargados"
                :headers="headers"
                :items="data.products"
                :items-per-page="5"
                :footer-props="{
                  'items-per-page-text': 'Filas por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:[`item.actions`]="{ item: product }">
                  <v-btn
                    color="red"
                    class="transparent-button-icon"
                    icon
                    @click="deleteProductList(product.productId || product.id)"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="data.description"
                outlined
                name="input-7-4"
                label="Observaciones"
                no-resize
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="red"
        text
        type="button"
        :disabled="fetchingProducts"
        @click="$emit('close')"
      >
        Cerrar
      </v-btn>
      <v-btn
        type="submit"
        color="success"
        :disabled="disabledSave"
        :loading="fetchingProducts"
      >
        Agregar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { TYPE_TRANSACTION } from '../../../../const'

  export default {
    name: 'AddConcessionsForm',
    data () {
      return {
        operation: TYPE_TRANSACTION.salida,
        isValidData: false,
        step: 1,
        salesUnits: ['UNIDAD', 'KG', 'M', 'LB'],
        headers: [
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Rollos',
            value: 'packingCant',
          },
          // {
          //   text: 'Unidad de empaque',
          //   value: 'packingUnit',
          // },
          {
            text: 'KG',
            value: 'cant',
          },
          // {
          //   text: 'Unidad',
          //   value: 'salesUnit',
          // },
          {
            text: 'Acciones',
            value: 'actions',
          },
        ],
        alert: false,
        productSelected: null,
        cant: 20,
        packingCant: 1,
        data: {
          description: '',
          products: [],
          relatedWarehouseId: null,
        },
      }
    },
    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'fetchingStoreWarehouses',
        'storeWarehouses',
        'fetchingProducts',
        'products',
        'searchParamsConcessions',
        'actionWarehouse',
        'warehousesInventory',
        'fechingWarehouseInventory',
        'actionEnvironmentData',
        'actionWarehouse',
      ]),

      typesOperation () {
        return [
          {
            text: 'Salida',
            value: TYPE_TRANSACTION.salida,
          },
          {
            text: 'Despacho',
            value: TYPE_TRANSACTION.concesion,
          },
        ]
      },

      saleUnit () {
        return this.productSelected !== null ? this.productSelected.salesUnit : '(unidad)'
      },

      packingUnit () {
        return this.productSelected !== null ? this.productSelected.packingUnit : '(empaque)'
      },

      getProducts () {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('output store products', this.products)
        let res = this.products.filter(item => item.status && this.getProdusctAvailable(item.id || item.productId))
        res = res.map(item => {
          item.name = item.codigo + ' - ' + item.description
          item.productId = item.productId ? item.productId : item.id
          delete item.id

          return item
        })
        return res
      },

      getCantbySelectProduct () {
        let res = this.warehousesInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.cant || 0)
      },

      getCantPackingBySelectProduct () {
        let res = this.warehousesInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_packing_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.packingCant || 0)
      },

      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },

      total () {
        let total = 0
        this.data.products.forEach((item, i) => {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log(i, item)
          total = total + (item.wholesalePrices * item.cant)
        })
        return total
      },

      disabledSave () {
        let res = false
        // for (const [key] of Object.entries(this.data)) {
        //   res = this.data[key] === false || this.data[key] === null
        // }
        if (this.data.products.length < 1) {
          res = true
        }
        return res
      },
    },

    methods: {
      ...mapActions([
        'fetchWarehouseConcessions',
        'createWarehouseConcessions',
        'fetchWarehouseInventory',
      ]),

      getProdusctAvailable (id) {
        const filter = this.warehousesInventory.filter(item => item?.productId === id)
        // // // // // // // // // // // // // // // // // // // // // console.log('available', filter)

        // // // // // // // // // // // // // // // // // // // // // // // // console.log('res', res)
        // res = res[0]?.quantity_available > 0

        // const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('cP', chargedProduct)
        return filter.length > 0 && filter[0].quantity_available > 0
      },

      addProductList () {
        if (this.cant <= this.getCantbySelectProduct) {
          if (this.productSelected && this.cant) {
            // // // // // // // // // // // // // // // // // // // // // // // // console.log('producto sleccionado', this.productSelected)
            if (this.data.products.filter(item => item.productId === this.productSelected.productId).length >= 1) {
              this.data.products = this.data.products.map(item => item.productId === this.productSelected.productId ? ({
                ...item,
                cant: Number(item.cant) + Number(this.cant),
                packingCant: Number(item.packingCant) + Number(this.packingCant),
              }) : item)
            } else {
              this.data.products.push({ ...this.productSelected, cant: this.cant, packingCant: this.packingCant })
            }
          }
          this.alert = false
          this.productSelected = null
          this.cant = 20
          this.packingCant = 1
        } else {
          this.alert = true
        }
      },

      deleteProductList (id) {
        this.data.products = this.data.products.filter(item => item.productId !== id)
      },

      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },

      assingMax () {
        this.cant = this.getCantbySelectProduct
      },

      resetData () {
        // for (const [key] of Object.entries(this.data)) {
        //   this.data[key] = '' // Making all keys of the object ''
        // }
        this.data = {
          description: '',
          products: [],
          relatedWarehouseId: null,
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.resetData()
        // await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
        await this.fetchWarehouseConcessions(this.searchParamsConcessions)
        await this.fetchWarehouseInventory({ id: this.actionWarehouse })
      },
      async submit (e) {
        e.preventDefault()
        // const businessId = this.businessId
        const total = this.total
        if (this.isValidData && this.businessId !== null && this.disabledSave === false) {
          // const submit = {

          // }
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('data', {
          //   ...this.data,
          //   total: total,
          //   })

          const submit = {
            ...this.data,
            typeTransactionId: this.operation,
            userId: this.user.id,
            warehouseId: this.actionWarehouse || this.$route.params.id,
            total: total,
            approvate: true,
            code: this.actionEnvironmentData.code,
            sucursalId: this.actionWarehouse,
          }

          // // // // // // // // // // // // // // // // // // // // // // // console.log('data', submit)

          // for (let i = 0; i <= 20000; i++) {
          await this.createWarehouseConcessions(submit)
          // }

          // const submit = {
          //   ...this.data,
          //   businessId,
          // }
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('sub', submit)
          // await this.createProducts(submit)
          this.afterCreate()
        }
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
