var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('v-row',{attrs:{"justify":"center","justify-md":"end"}},[_c('v-col',{attrs:{"cols":"3","md":"1"}},[_c('v-btn',{attrs:{"color":"orange","fab":"","text":"","large":""},on:{"click":function($event){_vm.openedAddDialog = !_vm.openedAddDialog}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1),_c('v-col',{attrs:{"cols":"3","md":"1"}},[_c('v-btn',{attrs:{"color":_vm.showAdd ? 'red' : 'orange',"fab":"","large":""},on:{"click":function($event){return _vm.$emit('show')}}},[(_vm.showAdd)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-autocomplete',{attrs:{"label":"Tiendas","loading":_vm.fetchingStoreWarehouses,"disabled":_vm.fetchingStoreWarehouses,"items":_vm.getStoreWarhouses,"item-text":"business","item-value":"warehouseId","no-data-text":"No hay coincidencia"},model:{value:(_vm.relatedWarehouseId),callback:function ($$v) {_vm.relatedWarehouseId=$$v},expression:"relatedWarehouseId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-autocomplete',{attrs:{"label":"Solicitudes","items":_vm.status,"no-data-text":"No hay coincidencia"},model:{value:(_vm.approved),callback:function ($$v) {_vm.approved=$$v},expression:"approved"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.start),"label":"Fecha de inicio","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalS),callback:function ($$v) {_vm.modalS=$$v},expression:"modalS"}},[_c('v-date-picker',{attrs:{"color":"light-blue darken-1","scrollable":"","locale":"es-ve"},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalS = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalS = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.end),"label":"Fecha final","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalE),callback:function ($$v) {_vm.modalE=$$v},expression:"modalE"}},[_c('v-date-picker',{attrs:{"scrollable":"","color":"light-blue darken-1","locale":"es-ve"},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalE = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalE = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-autocomplete',{attrs:{"label":"Tipo de operacion","items":_vm.typeFilter,"item-text":"name","item-value":"value","no-data-text":"No hay coincidencia"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-autocomplete',{attrs:{"label":"Códigos","items":_vm.codes,"item-text":"name","item-value":"value","no-data-text":"No hay coincidencia"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1),(_vm.type !== 0 && _vm.approved === 'SIN APROBAR')?_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"disabled":_vm.fetchingWarehouseConcessions,"color":"green","icon":""},on:{"click":_vm.aprobeAll}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v(" mdi-check-all ")])],1)]}}],null,false,3105551201)},[_c('span',[_vm._v("Presione aprobar masivamente todas las transacciones seleccionadas por el filtro")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"disabled":_vm.fetchingWarehouseConcessions,"color":"red","icon":""},on:{"click":_vm.noAprobeAll}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v(" mdi-delete ")])],1)]}}],null,false,3820740475)},[_c('span',[_vm._v("Presione denegar masivamente todas las transacciones seleccionadas por el filtro")])])],1)],1):_vm._e(),_c('add-concessions',{model:{value:(_vm.openedAddDialog),callback:function ($$v) {_vm.openedAddDialog=$$v},expression:"openedAddDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }