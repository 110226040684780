<template>
  <div>
    <!-- <pre>{{ item }}</pre> -->
    <v-tooltip
      v-if="!item.approved"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingWarehouseConcessions"
          color="green"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="modalA = true"
        >
          <v-icon>
            mdi-check-bold
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para aceptar la transacción</span>
    </v-tooltip>

    <v-tooltip
      v-if="!item.approved"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingWarehouseConcessions"
          color="red"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="modalE = true"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para denegar la transacción</span>
    </v-tooltip>

    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="light-blue darken-1"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="openedEditDialog = true"
        >
          <v-icon size="25">
            mdi-magnify-plus-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para ver el detalle de la operación</span>
    </v-tooltip>

    <v-tooltip
      v-if="item.approved"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="primary"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="downloadPdf"
        >
          <v-icon size="25">
            mdi-download
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para descargar el documento</span>
    </v-tooltip>

    <v-tooltip
      v-if="item.approved"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="secondary"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="printPdf"
        >
          <v-icon size="25">
            mdi-printer
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para imprimir el documento</span>
    </v-tooltip>

    <!-- <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn> -->
    <concessions-detail-dialog
      :id="item"
      v-model="openedEditDialog"
    />

    <!-- Confirm approve -->
    <v-dialog
      v-model="modalA"
      max-width="530"
    >
      <v-card>
        <v-card-title class="text-h3">
          ¿Seguro de querer aceptar esta operación?
        </v-card-title>

        <v-card-text class="text-center grey--text">
          Esta accion creara un registro permanente en la aplicación, ¿Desea continuar?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="modalA = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            :loading="fetchingWarehouseConcessions"
            @click="approve"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm delete -->

    <v-dialog
      v-model="modalE"
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h3">
          ¿Seguro de querer eliminar esta solicitud?
        </v-card-title>

        <v-card-text class="text-center grey--text">
          Esta accion creara un registro permanente en la aplicación, ¿Desea continuar?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="modalE = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            :loading="fetchingWarehouseConcessions"
            @click="deleteTransaction"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import ConcessionsDetailDialog from './ConcessionsDetailDialog.vue'
  import { mapState, mapActions } from 'vuex'
  import { TYPE_TRANSACTION } from '../../../../const'
  import { jsPDF as JsPdf } from 'jspdf'
  import 'jspdf-autotable'

  export default {
    name: 'ConcessionsActions',
    components: {
      ConcessionsDetailDialog,
    },
    props: {
      item: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        modalA: false,
        modalE: false,
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingWarehouseConcessions',
        'user',
        'actionsParentBusiness',
        'searchParamsConcessions',
        'actionWarehouse',
        'fetchingWarehouseInputsDetail',
        'warhouseInputsDetail',
        'businessWarehouses',
        'fetchingProducts',
        'clientsSearch',
        'salePayments',
        'saleSurplus',
      ]),
    },
    methods: {
      ...mapActions([
        'aproveWarhouseConcessionsByCode',
        'deleteWarhouseConcessionsByCode',
        'fetchWarehouseConcessions',
        'fetchWarehouseInventory',
        'aproveWarhouseConcessionsById',
        'deleteWarhouseConcessionsById',
        'fetchWarhouseInputsDetail',
        'getBusinessByWarehousesId',
        'fetchClientById',
        'fetchBusinessSalesPayments',
        'fetchBusinessSalesSurplus',
      ]),

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },

      getImageByUrl (url) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onError = () => reject(
            new Error(`Cannot load image ${url}`),
          )
          img.onload = () => resolve(img)
          img.src = url
        })
      },

      async genPdf (print = false) {
        // // // // // // // // // // // // // // // // console.log('ITEM', this.item)
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        // const img = await this.getImageByUrl('/logo.png')

        // // // // // // // // // // // // // // // console.log('ITEM', this.item)

        if (this.item.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion) {
          // doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
          // doc.setTextColor(255, 0, 0)
          // doc.text(`CÓDIGO: ${this.item.code}`, 130, 20)
          // doc.setTextColor(0, 0, 0)
          // doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)
          // doc.setFontSize(13)
          // doc.setTextColor(0, 0, 0).text('Documento de identidad:', 10, 35)
          // doc.setTextColor(100, 100, 100).text(this.clientsSearch?.ci, 63, 35)
          // doc.setTextColor(0, 0, 0).text('Nombres: ', 10, 42)
          // doc.setTextColor(100, 100, 100).text(this.clientsSearch?.name, 32, 42)

          // if (this.clientsSearch?.lastname) {
          //   doc.setTextColor(0, 0, 0).text('Apellidos:', 10, 49)
          //   doc.setTextColor(100, 100, 100).text(this.clientsSearch?.lastname, 32, 49)
          // }

          // doc.setTextColor(0, 0, 0).text('Email:', 10, 60)
          // if (this.clientsSearch?.email) {
          //   doc.setTextColor(100, 100, 100).text(this.clientsSearch.email, 25, 60)
          // }

          // doc.setTextColor(0, 0, 0).text('Teléfono:', 10, 67)
          // if (this.clientsSearch?.phone) {
          //   doc.setTextColor(100, 100, 100).text(this.clientsSearch.phone, 32, 67)
          // }

          // doc.setTextColor(0, 0, 0).text('Dirección:', 10, 74)
          // if (this.clientsSearch?.direction) {
          //   doc.setTextColor(100, 100, 100).text(this.clientsSearch.direction, 33, 74)
          // }

          // doc.setFontSize(18).setTextColor(0, 0, 0).text('Presupuesto', 88, 90)

          // // // // // // // // // // // // // console.log('salePayment', this.salePayments)
          // // // // // // // // // // // // // console.log('saleSurplus', this.saleSurplus)

          // doc.setFontSize(30).text('Tejidos Evertex C.A', 10, 20)
          // doc.setFontSize(17).text('Rif J- 30652826-1', 12, 30)
          // doc.setFontSize(12).setTextColor(100, 100, 100).text('Direccion:Zona Industrial Santa Cruz\nMaturín Centro Industrial Ferro A-1', 12, 37)

          doc.setFontSize(35).text('Sistema Genérico', 10, 23)

          doc.setTextColor(255, 0, 0)
          doc.setFontSize(18).text(`Presupuesto: ${this.item.code}`, 120, 20)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 120, 27)
          doc.setFontSize(15).text('Vendedor:', 120, 35)

          doc.setFontSize(14).setTextColor(100, 100, 100).text(this.item.userName + ' ' + this.item.userLastName, 146, 35)

          doc.setFontSize(18).setTextColor(0, 0, 0).text('Datos Cliente', 10, 45)

          doc.setFontSize(13)

          doc.setTextColor(0, 0, 0).text('Nombres: ', 10, 55)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.name + ' ' + this.clientsSearch?.lastname || '', 32, 55)

          doc.setTextColor(0, 0, 0).text('C.I / RIF: ', 130, 55)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.ci, 150, 55)

          doc.setTextColor(0, 0, 0).text('Dirección: ', 10, 63)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.direction ? this.clientsSearch.direction.slice(0, 105) : '' || '', 32, 63)

          doc.setTextColor(0, 0, 0).text('Teléfono: ', 10, 75)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.phone || '', 30, 75)

          doc.setTextColor(0, 0, 0).text('Email: ', 80, 75)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.email || '', 94, 75)

          doc.setTextColor(0, 0, 0)

          doc.setFontSize(16)
          doc.text('Productos', 90, 90)

          doc.autoTable({
            columns: [
              { title: 'Código', dataKey: 'codigo' },
              { title: 'Descripción', dataKey: 'description' },
              { title: 'Rollos', dataKey: 'packingCant' },
              // { title: 'Unidad de empaque', dataKey: 'packingUnit' },
              { title: 'KG', dataKey: 'cant' },
              { title: 'Precio', dataKey: 'amountWithoutTax' },
              { title: 'Subtotal', dataKey: 'subtotal' },
            ],
            body: this.warhouseInputsDetail.map(item => {
              return {
                ...item,
                cant: Math.abs(item.cant),
                packingCant: Math.abs(item.packingCant),
                subtotal: this.formatPrice(Math.abs(item.cant * item.amountWithoutTax)),
                amountWithoutTax: this.formatPrice(item.amountWithoutTax),
              }
            }),
            margin: {
              left: 10,
              top: 95,
            },
          })

          doc.setLineWidth(0.5).line(0, 165, doc.internal.pageSize.width, 165)
          // doc.setLineWidth(0.5).line(0, 90, doc.internal.pageSize.width, 90)
          doc.setLineWidth(0.5).line(0, 80, doc.internal.pageSize.width, 80)

          doc.text('Métodos de pago', 83, 175)

          doc.autoTable({
            columns: [
              { title: 'Método de importe', dataKey: 'paymentMethods' },
              { title: 'Moneda de importe', dataKey: 'currency' },
              { title: 'Valor de la moneda de importe', dataKey: 'amountCurrencyReference' },
              { title: 'Monto', dataKey: 'amount' },
              { title: 'Monto en $', dataKey: 'totalAmountCurrencyReference' },
            ],
            body: this.salePayments.map(item => {
              // // // // // // // // // // // // // // // console.log('product', item)
              return {
                ...item,
                paymentMethods: item.paymentMethods.toUpperCase(),
                amountCurrencyReference: this.formatPrice(
                  item.amountCurrencyReference,
                  item.currencyId === 1 ? 'USD'
                  : item.currencyId === 2 ? 'EUR' : 'VES',
                ),
                amount: this.formatPrice(
                  item.amount,
                  item.currencyId === 1 ? 'USD'
                  : item.currencyId === 2 ? 'EUR' : 'VES',
                ),
                totalAmountCurrencyReference: this.formatPrice(item.totalAmountCurrencyReference),
              }
            }),
            margin: {
              left: 10,
              top: 1100,
              right: 10,
            },
            startY: 183,
          })

          if (this.saleSurplus.length > 0) {
            doc.autoTable({
              columns: [
                { title: 'Método de retorno', dataKey: 'paymentMethods' },
                { title: 'Moneda de retorno', dataKey: 'currency' },
                { title: 'Valor de la moneda de retorno', dataKey: 'amountCurrencyReference' },
                { title: 'Monto', dataKey: 'amount' },
                { title: 'Monto en $', dataKey: 'totalAmountCurrencyReference' },
              ],
              body: this.saleSurplus.map(item => {
                // // // // // // // // // // // // // // // console.log('product', item)
                return {
                  ...item,
                  paymentMethods: item.paymentMethods.toUpperCase(),
                  amountCurrencyReference: this.formatPrice(
                    item.amountCurrencyReference,
                    item.currencyId === 1 ? 'USD'
                    : item.currencyId === 2 ? 'EUR' : 'VES',
                  ),
                  amount: this.formatPrice(
                    item.amount,
                    item.currencyId === 1 ? 'USD'
                    : item.currencyId === 2 ? 'EUR' : 'VES',
                  ),
                  totalAmountCurrencyReference: this.formatPrice(item.totalAmountCurrencyReference),

                }
              }),
              margin: {
                left: 10,
                top: 1100,
                right: 10,

              },
              // startY: 210,
            })
          }

          doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 40, doc.internal.pageSize.width, doc.internal.pageSize.height - 40)
          doc.setFontSize(10)
          doc.text('Observaciones:', 10, doc.internal.pageSize.height - 35)

          doc.setFontSize(8)
          doc.text(this.item.description, 10, doc.internal.pageSize.height - 30)
          doc.setFontSize(12).setTextColor(100, 100, 100).text(`Subtotal:                ${this.formatPrice(this.item.totalAmountCurrencyReference)}`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 31)
          doc.setFontSize(12).setTextColor(100, 100, 100).text(`% de descuento:   ${this.item.discount}%`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 25)
          doc.setFontSize(12).setTextColor(100, 100, 100).text(`Monto descuento: ${this.formatPrice(((this.item.totalAmountCurrencyReference * this.item.discount) / 100))}`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 19)
          doc.setFontSize(15)
          doc.setTextColor(0, 0, 0)
          doc.text(`Total:               ${this.formatPrice(this.item.totalAmountCurrencyReference - ((this.item.totalAmountCurrencyReference * this.item.discount) / 100))}`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 10)

          doc.setLineWidth(0.5).line(75, doc.internal.pageSize.height - 17, 135, doc.internal.pageSize.height - 17)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma y Sello', 96, doc.internal.pageSize.height - 10)

          if (print) {
            doc.autoPrint()
            doc.output('dataurlnewwindow')
          } else {
            doc.save('presupuesto.pdf')
          }
        } else if (this.item.typeTransactionId === TYPE_TRANSACTION.concesion) {
          // doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
          doc.setFontSize(36).text('Sistema Genérico', 10, 23)
          doc.setTextColor(255, 0, 0)
          doc.setFontSize(18).text(`CÓDIGO: ${this.item.code}`, 130, 20)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)
          // doc.setTextColor(100, 100, 100)
          doc.setFontSize(13)
          doc.setTextColor(0, 0, 0).text('Solicitante:', 10, 35)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.name, 34, 35)
          doc.setTextColor(0, 0, 0).text('R.I.F.:', 10, 42)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.rif, 25, 42)
          doc.setTextColor(0, 0, 0).text('Dirección:', 10, 49)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.address, 33, 49)

          doc.setTextColor(0, 0, 0).text('Email:', 10, 60)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.email, 25, 60)
          doc.setTextColor(0, 0, 0).text('Teléfono1:', 10, 67)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.phone1, 33, 67)
          if (this.businessWarehouses.phone2) {
            doc.setTextColor(0, 0, 0).text('Teléfono2:', 100, 67)
            doc.setTextColor(100, 100, 100).text(this.businessWarehouses.phone2, 124, 67)
          }

          doc.setFontSize(18).setTextColor(0, 0, 0).text('Nota de entrega', 82, 90)
          // doc.text('Nota de entrega', 82, 75)

          doc.autoTable({
            columns: [
              { title: 'Codigo del producto', dataKey: 'codigo' },
              { title: 'Nombre del producto', dataKey: 'description' },
              { title: 'Rollos', dataKey: 'packingCant' },
              // { title: 'Unidad de empaque', dataKey: 'packingUnit' },
              { title: 'KG', dataKey: 'cant' },
              { title: 'Precio Unitario', dataKey: 'amount_total' },
              { title: 'Precio Total', dataKey: 'total' },
            ],
            body: this.warhouseInputsDetail.map(item => {
              return {
                ...item,
                cant: Math.abs(item.cant),
                packingCant: Math.abs(item.packingCant),
                total: this.formatPrice(Math.abs(item.cant) * item.amountWithoutTax),
                amount_total: this.formatPrice(item.amountWithoutTax),
              }
            }),
            margin: {
              left: 10,
              top: 100,
            },
          })

          // // // // // // console.log(doc.internal.pageSize.width)

          doc.setLineWidth(0.5).line(10, doc.internal.pageSize.height - 60, 70, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Nombre de quien recibe', 20, doc.internal.pageSize.height - 53)
          doc.setLineWidth(0.5).line(75, doc.internal.pageSize.height - 60, 140, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('C.I. de quien recibe', 92, doc.internal.pageSize.height - 53)
          doc.setLineWidth(0.5).line(145, doc.internal.pageSize.height - 60, 205, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma de quien recibe', 155, doc.internal.pageSize.height - 53)

          doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 30, doc.internal.pageSize.width, doc.internal.pageSize.height - 30)
          doc.setFontSize(10)
          doc.setTextColor(0, 0, 0)
          doc.text('Observaciones:', 10, doc.internal.pageSize.height - 25)
          doc.setFontSize(8)
          doc.text(this.item.description, 10, doc.internal.pageSize.height - 20)
          doc.setFontSize(15)
          doc.text(`Total: ${this.formatPrice(this.item.totalAmountCurrencyReference)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 10)

          // doc.autoPrint()
          // doc.output('dataurlnewwindow')
          if (print) {
            doc.autoPrint()
            doc.output('dataurlnewwindow')
          } else {
            doc.save('nota-de-entrega.pdf')
          }
        } else {
          doc.setFontSize(38).text('Sistema Genérico', 10, 23)
          doc.setTextColor(255, 0, 0)
          doc.setFontSize(18).text(`CÓDIGO: ${this.item.code}`, 130, 20)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)

          doc.setTextColor(0, 0, 0)
          doc.setFontSize(18).setTextColor(0, 0, 0).text(`Recepción de devolución de la sucursal ${this.item.relatedWarehouse}`, 45, 45)

          doc.autoTable({
            headStyles: { fontSize: 14 },
            styles: { fontSize: 12, halign: 'center' },
            // columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } },
            columns: [
              { title: 'Tipo de tela', dataKey: 'description' },
              { title: 'Rollos', dataKey: 'packingCant' },
              // { title: 'Unidad de empaque', dataKey: 'packingUnit' },
              { title: 'KG', dataKey: 'cant' },
              { title: 'Motivo de la devolución', dataKey: 'motive' },
            ],
            body: this.warhouseInputsDetail.map(item => {
              return {
                ...item,
                packingCant: Math.abs(item.packingCant),
                cant: Math.abs(item.cant),
                motive: this.item.description,
              }
            }),
            margin: {
              left: 10,
              top: 65,
            },
          })

          doc.setLineWidth(0.5).line(10, doc.internal.pageSize.height - 60, 70, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma de entrega', 25, doc.internal.pageSize.height - 53)

          doc.setLineWidth(0.5).line(145, doc.internal.pageSize.height - 60, 205, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma recibido', 164, doc.internal.pageSize.height - 53)

          if (print) {
            doc.autoPrint()
            doc.output('dataurlnewwindow')
          } else {
            doc.save('nota-de-devolucion.pdf')
          }
        }
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      async downloadPdf () {
        await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.getBusinessByWarehousesId({ id: this.item.relatedWarehouseId })
        await this.fetchClientById(this.item.clientId)
        await this.fetchBusinessSalesPayments({ id: this.item.id })
        await this.fetchBusinessSalesSurplus({ id: this.item.id })
        await this.genPdf()
      },

      async approve () {
        if (this.item.typeTransactionId === TYPE_TRANSACTION.concesion) {
          const form = {
            code: this.item.code,
            approvedByUserId: this.user.id,
          }
          await this.aproveWarhouseConcessionsByCode(form)
        } else if (this.item.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion || this.item.typeTransactionId === TYPE_TRANSACTION.devolucion_por_concesion) {
          // const form = {
          //   id: this.item.id,
          //   approvedByUserId: this.user.id,
          // }
          // await this.aproveWarhouseConcessionsById(form)

          const form = {
            code: this.item.code,
            approvedByUserId: this.user.id,
          }
          await this.aproveWarhouseConcessionsByCode(form)
        }

        this.modalA = false

        await this.fetchWarehouseConcessions(this.searchParamsConcessions)
        await this.fetchWarehouseInventory({ id: this.actionWarehouse })
      },

      async deleteTransaction () {
        if (this.item.typeTransactionId === TYPE_TRANSACTION.concesion || this.item.typeTransactionId === TYPE_TRANSACTION.devolucion_por_concesion) {
          const form = {
            code: this.item.code,
            approvedByUserId: this.user.id,
          }
          await this.deleteWarhouseConcessionsByCode(form)
        } else if (this.item.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion) {
          const form = {
            id: this.item.id,
            approvedByUserId: this.user.id,
          }
          await this.deleteWarhouseConcessionsById(form)
        }

        this.modalE = false

        await this.fetchWarehouseConcessions(this.searchParamsConcessions)
        await this.fetchWarehouseInventory({ id: this.actionWarehouse })
      },

      async printPdf () {
        await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.getBusinessByWarehousesId({ id: this.item.relatedWarehouseId })
        await this.fetchClientById(this.item.clientId)
        await this.fetchBusinessSalesPayments({ id: this.item.id })
        await this.fetchBusinessSalesSurplus({ id: this.item.id })
        await this.genPdf(true)
      },
      // async onDeactiveWarehouse () {
      //   await this.deleteProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
      // async onActiveWarehouse () {
      //   await this.activeProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
