<template>
  <v-container
    fluid
    tag="section"
  >
    <!-- <pre>{{ warehousesConcessions }}</pre> -->
    <!-- <pre>{{ concessions }}</pre> -->
    <!-- {{ operation }} -->
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-certificate-outline"
          title="Salidas, despachos, presupuestos y devoluciones"
          class="px-5 py-3"
          color="orange"
        >
          <!-- <v-card-title>
            <h1>hola</h1>
          </v-card-title> -->
          <!-- :type="type" -->
          <concessions-table-top
            :type-filter="typeFilter"
            :show-add="showAdd"
            :concessions="concessions"
            @changeType="changeType"
            @changeCode="changeCode"
            @show="showAdd = !showAdd"
          />

          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busca por cualquier campo"
              single-line
              hide-details
            />
          </v-container>
          <!-- <v-row
            v-if="code !== 'TODOS' && (type === 1 || type === 3)"
            justify="center"
          >
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="fetchingWarehouseConcessions"
                  color="green"
                  class="transparent-button-icon"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="modalA = true"
                >
                  <v-icon>
                    mdi-check-all
                  </v-icon>
                </v-btn>
              </template>
              <span>Presione para aprobar la solicitud de concesion</span>
            </v-tooltip>

            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="fetchingWarehouseConcessions"
                  color="red"
                  class="transparent-button-icon"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="modalE = true"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Presione para eliminar la solicitud de concesion</span>
            </v-tooltip>
          </v-row> -->
          <!-- {{ code }} -->
          <v-row
            v-if="code !== 'TODOS' && type !== 0"
            justify="center"
          >
            <!-- <v-col cols="2">
              <v-btn
                block
              >
                hola
              </v-btn>
            </v-col> -->
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="fetchingProducts"
                  color="primary"
                  class="transparent-button-icon"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadPdf"
                >
                  <v-icon size="35">
                    mdi-download
                  </v-icon>
                </v-btn>
              </template>
              <span>Presione para descargar la orden de entrada</span>
            </v-tooltip>

            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="fetchingProducts"
                  color="secondary"
                  class="transparent-button-icon"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="printPdf"
                >
                  <v-icon size="35">
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Presione para imprimir la orden de entrada</span>
            </v-tooltip>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="concessions"
            :item-key="'_id'"
            :loading="fetchingWarehouseConcessions"
            :search="search"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template
              v-if="showAdd"
              v-slot:[`body.prepend`]="{}"
            >
              <br>
              <tr>
                <!-- <th /> -->
                <th>
                  <v-autocomplete
                    v-if="operation !== TYPE_TRANSACTION.salida"
                    v-model="data.relatedWarehouseId"
                    label="Sucursal"
                    :loading="fetchingStoreWarehouses"
                    :disabled="fetchingStoreWarehouses"
                    :items="storeWarehouses"
                    item-text="business"
                    item-value="warehouseId"
                    no-data-text="No hay coincidencia"
                    outlined
                  />
                </th>
                <th colspan="2">
                  <v-autocomplete
                    v-model="productSelected"
                    label="Productos"
                    :loading="fetchingProducts"
                    :disabled="fetchingProducts"
                    :items="getProducts"
                    item-text="name"
                    no-data-text="No hay coincidencia"
                    return-object
                    outlined
                  />
                  <!-- <v-row>
                    <v-col>
                      <v-text-field
                        v-model="packingCant"
                        type="number"
                        min="1"
                        :rules="requiredFieldRules('Cantidad es requerido')"
                        outlined
                        label="Rollos"
                        required
                        :hint="'Disponible: ' + getCantPackingBySelectProduct"
                        persistent-hint
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="cant"
                        type="number"
                        min="1"
                        :rules="requiredFieldRules('Cantidad es requerido')"
                        outlined
                        label="KG"
                        required
                        persistent-hint
                        :hint="'Disponible :' + getCantbySelectProduct"
                      />
                    </v-col>
                  </v-row> -->
                </th>
                <th>
                  <v-autocomplete
                    v-model="operation"
                    label="Operación"
                    :loading="fetchingProducts"
                    :disabled="fetchingProducts"
                    :items="typesOperation"
                    no-data-text="No hay coincidencia"
                    outlined
                  />
                </th>
                <th>
                  <v-text-field
                    v-model="packingCant"
                    type="number"
                    min="1"
                    :rules="requiredFieldRules('Cantidad es requerido')"
                    outlined
                    label="Rollos"
                    required
                    :hint="'Disponible: ' + getCantPackingBySelectProduct"
                    persistent-hint
                  />
                </th>
                <th colspan="2">
                  <v-text-field
                    v-model="cant"
                    type="number"
                    min="1"
                    :rules="requiredFieldRules('Cantidad es requerido')"
                    outlined
                    label="KG"
                    required
                    persistent-hint
                    :hint="'Disponible :' + getCantbySelectProduct"
                  />
                </th>

                <th colspan="2">
                  <v-text-field
                    v-model="data.description"
                    outlined
                    name="input-7-4"
                    label="Observaciones"
                    no-resize
                  />
                </th>
                <!-- <th />
                <th />
                <th /> -->
                <th>
                  <v-btn
                    icon
                    class="transparent-button-icon"
                    type="submit"
                    color="success"
                    :disabled="disabledSave"
                    :loading="fetchingProducts"
                    @click="submit"
                  >
                    <v-icon size="28">
                      mdi-check-all
                    </v-icon>
                  </v-btn>
                </th>
              </tr>
            </template>
            <!-- <template v-slot:top>
            </template> -->
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>
            <template v-slot:[`item.typeTransaction`]="{ item: input }">
              <span
                v-if="input.typeTransactionId === TYPE_TRANSACTION.concesion"
                class="orange--text"
              >
                <!-- Solicitud de concesión -->
                Despacho
              </span>
              <span
                v-else-if="input.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion"
                class="green--text"
              >
                Presupuesto
              </span>
              <span
                v-else-if="input.typeTransactionId === TYPE_TRANSACTION.devolucion_por_concesion"
                class="red--text"
              >
                Devolución
              </span>
              <span
                v-else-if="input.typeTransactionId === TYPE_TRANSACTION.salida"
                class="red--text"
              >
                Salida
              </span>
            </template>
            <template v-slot:[`item.total`]="{ item: input }">
              <span>
                {{ input.totalAmountCurrencyReference | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.tr`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.tk`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.cantP`]="{ item }">
              <span> {{ Math.abs(item.cantP) }} </span>
            </template>
            <template v-slot:[`item.packingCantP`]="{ item }">
              <span> {{ Math.abs(item.packingCantP) }} </span>
            </template>
            <template v-slot:[`item.actions`]="{ item: item }">
              <concessions-actions
                :item="item"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import ConcessionsTableTop from './ConcessionsTableTop.vue'
  import ConcessionsActions from './ConcessionsActions.vue'
  import { TYPE_TRANSACTION } from '../../../../const'
  import { jsPDF as JsPdf } from 'jspdf'
  import 'jspdf-autotable'

  export default {
    name: 'ConcessionsTable',

    components: {
      ConcessionsTableTop,
      ConcessionsActions,
    },

    filters: {
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

    data () {
      return {
        code: 'TODOS',
        isValidData: false,
        step: 1,
        salesUnits: ['UNIDAD', 'KG', 'M', 'LB'],
        alert: false,
        productSelected: null,
        cant: 20,
        packingCant: 1,
        operation: TYPE_TRANSACTION.salida,
        data: {
          description: '',
          products: [],
          relatedWarehouseId: null,
        },
        showAdd: false,
        TYPE_TRANSACTION,
        search: '',
        type: 0,
        headers: [
          {
            text: 'Fecha',
            value: 'createdAt',
          },
          {
            text: 'Sucursal',
            value: 'relatedWarehouse',
          },
          {
            text: 'Código',
            value: 'code',
          },
          {
            text: 'Producto',
            value: 'productName',
          },
          {
            text: 'Tipo de operación',
            value: 'typeTransaction',
          },
          {
            text: 'Total Rollos',
            value: 'packingCantP',
          },
          {
            text: 'Total KG',
            value: 'cantP',
          },
          {
            text: 'Total',
            value: 'total',
          },
          {
            text: 'Observaciones',
            value: 'description',
          },
          // {
          //   text: 'Acciones',
          //   value: 'actions',
          //   align: 'center',
          //   sortable: false,
          // },
        ],

        typeFilter: [
          {
            name: 'TODAS',
            value: 0,
          },
          {
            name: 'PRESUPUESTOS',
            value: 1,
          },
          {
            name: 'DESPACHOS',
            value: 2,
          },
          {
            name: 'DEVOLUCIONES',
            value: 3,
          },
          {
            name: 'SALIDAS',
            value: 4,
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'fetchingStoreWarehouses',
        'storeWarehouses',
        'fetchingProducts',
        'products',
        'searchParamsConcessions',
        'actionWarehouse',
        'warehousesInventory',
        'fechingWarehouseInventory',
        'actionEnvironmentData',
        'actionWarehouse',
        'fetchingWarehouseConcessions',
        'warehousesConcessions',
        'salePayments',
        'saleSurplus',
        'clientsSearch',
        'businessWarehouses',
      ]),

      typesOperation () {
        return [
          {
            text: 'Salida',
            value: TYPE_TRANSACTION.salida,
          },
          {
            text: 'Despacho',
            value: TYPE_TRANSACTION.concesion,
          },
        ]
      },

      saleUnit () {
        return this.productSelected !== null ? this.productSelected.salesUnit : '(unidad)'
      },

      packingUnit () {
        return this.productSelected !== null ? this.productSelected.packingUnit : '(empaque)'
      },

      getProducts () {
        // // // // // console.log('output store products', this.products)
        let res = this.products.filter(item => item.status && this.getProdusctAvailable(item.id || item.productId))
        res = res.map(item => {
          item.name = item.codigo + ' - ' + item.description
          item.productId = item.productId ? item.productId : item.id
          delete item.id

          return item
        })
        return res
      },

      getCantbySelectProduct () {
        let res = this.warehousesInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.cant || 0)
      },

      getCantPackingBySelectProduct () {
        let res = this.warehousesInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_packing_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.packingCant || 0)
      },

      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },

      total () {
        let total = 0
        this.data.products.forEach((item, i) => {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log(i, item)
          total = total + (item.wholesalePrices * item.cant)
        })
        return total
      },

      disabledSave () {
        let res = false
        // for (const [key] of Object.entries(this.data)) {
        //   res = this.data[key] === false || this.data[key] === null
        // }

        if (this.operation !== TYPE_TRANSACTION.salida) {
          if (!(this.productSelected && this.cant && this.data.relatedWarehouseId)) {
            res = true
          }
        } else {
          if (!(this.productSelected && this.cant)) {
            res = true
          }
        }

        return res
      },

      concessions () {
        let res = []
        if (this.type === 0) {
          res = this.warehousesConcessions
        } else if (this.type === 1) {
          res = this.warehousesConcessions.filter(item => item.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion)
        } else if (this.type === 2) {
          res = this.warehousesConcessions.filter(item => item.typeTransactionId === TYPE_TRANSACTION.concesion)
        } else if (this.type === 3) {
          res = this.warehousesConcessions.filter(item => item.typeTransactionId === TYPE_TRANSACTION.devolucion_por_concesion)
        } else if (this.type === 4) {
          res = this.warehousesConcessions.filter(item => item.typeTransactionId === TYPE_TRANSACTION.salida)
        }

        return this.code !== 'TODOS' ? res.filter(item => item.code === this.code) : res
        // return res
      },
    },

    methods: {
      ...mapActions([
        'fetchWarehouseConcessions',
        'createWarehouseConcessions',
        'fetchWarehouseInventory',
        'getBusinessByWarehousesId',
        'fetchClientById',
        'fetchBusinessSalesPayments',
        'fetchBusinessSalesSurplus',
      ]),

      changeType (v) {
        this.type = v
      },

      changeCode (v) {
        this.code = v
      },

      getProdusctAvailable (id) {
        const filter = this.warehousesInventory.filter(item => item?.productId === id)
        return filter.length > 0 && filter[0].quantity_available > 0
      },

      addProductList () {
        if (this.cant <= this.getCantbySelectProduct) {
          if (this.productSelected && this.cant) {
            if (this.data.products.filter(item => item.productId === this.productSelected.productId).length >= 1) {
              this.data.products = this.data.products.map(item => item.productId === this.productSelected.productId ? ({
                ...item,
                cant: Number(item.cant) + Number(this.cant),
                packingCant: Number(item.packingCant) + Number(this.packingCant),
              }) : item)
            } else {
              this.data.products.push({ ...this.productSelected, cant: this.cant, packingCant: this.packingCant })
            }
          }
          this.alert = false
          this.productSelected = null
          this.cant = 1
          this.packingCant = 1
        } else {
          this.alert = true
        }
      },

      deleteProductList (id) {
        this.data.products = this.data.products.filter(item => item.productId !== id)
      },

      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },

      assingMax () {
        this.cant = this.getCantbySelectProduct
      },

      resetData () {
        // for (const [key] of Object.entries(this.data)) {
        //   this.data[key] = '' // Making all keys of the object ''
        // }
        this.data = {
          description: '',
          products: [],
          relatedWarehouseId: null,
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.showAdd = false
        this.resetData()
        // await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
        await this.fetchWarehouseConcessions(this.searchParamsConcessions)
        await this.fetchWarehouseInventory({ id: this.actionWarehouse })
      },

      async downloadPdf () {
        this.item = this.concessions[0]
        // await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.getBusinessByWarehousesId({ id: this.item.relatedWarehouseId || 0 })
        await this.fetchClientById(this.item.clientId || 0)
        await this.fetchBusinessSalesPayments({ id: this.item.id })
        await this.fetchBusinessSalesSurplus({ id: this.item.id })
        await this.genPdf()
      },

      async printPdf () {
        this.item = this.concessions[0]
        // console.log(this.item)
        // await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.getBusinessByWarehousesId({ id: this.item.relatedWarehouseId || 0 })
        await this.fetchClientById(this.item.clientId || 0)
        await this.fetchBusinessSalesPayments({ id: this.item.id })
        await this.fetchBusinessSalesSurplus({ id: this.item.id })
        await this.genPdf(true)
      },

      getImageByUrl (url) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onError = () => reject(
            new Error(`Cannot load image ${url}`),
          )
          img.onload = () => resolve(img)
          img.src = url
        })
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      async genPdf (print = false) {
        // // // // // // // // // // // // // // // // console.log('ITEM', this.item)
        this.item = this.concessions[0]
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        // const img = await this.getImageByUrl('/logo.png')

        // // // // // // // // // // // // // // // console.log('ITEM', this.item)

        if (this.item.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion) {
          // doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
          // doc.setTextColor(255, 0, 0)
          // doc.text(`CÓDIGO: ${this.item.code}`, 130, 20)
          // doc.setTextColor(0, 0, 0)
          // doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)
          // doc.setFontSize(13)
          // doc.setTextColor(0, 0, 0).text('Documento de identidad:', 10, 35)
          // doc.setTextColor(100, 100, 100).text(this.clientsSearch?.ci, 63, 35)
          // doc.setTextColor(0, 0, 0).text('Nombres: ', 10, 42)
          // doc.setTextColor(100, 100, 100).text(this.clientsSearch?.name, 32, 42)

          // if (this.clientsSearch?.lastname) {
          //   doc.setTextColor(0, 0, 0).text('Apellidos:', 10, 49)
          //   doc.setTextColor(100, 100, 100).text(this.clientsSearch?.lastname, 32, 49)
          // }

          // doc.setTextColor(0, 0, 0).text('Email:', 10, 60)
          // if (this.clientsSearch?.email) {
          //   doc.setTextColor(100, 100, 100).text(this.clientsSearch.email, 25, 60)
          // }

          // doc.setTextColor(0, 0, 0).text('Teléfono:', 10, 67)
          // if (this.clientsSearch?.phone) {
          //   doc.setTextColor(100, 100, 100).text(this.clientsSearch.phone, 32, 67)
          // }

          // doc.setTextColor(0, 0, 0).text('Dirección:', 10, 74)
          // if (this.clientsSearch?.direction) {
          //   doc.setTextColor(100, 100, 100).text(this.clientsSearch.direction, 33, 74)
          // }

          // doc.setFontSize(18).setTextColor(0, 0, 0).text('Presupuesto', 88, 90)

          // // // // // // // // // // // // // console.log('salePayment', this.salePayments)
          // // // // // // // // // // // // // console.log('saleSurplus', this.saleSurplus)

          // doc.setFontSize(30).text('Tejidos Evertex C.A', 10, 20)
          // doc.setFontSize(17).text('Rif J- 30652826-1', 12, 30)
          // doc.setFontSize(12).setTextColor(100, 100, 100).text('Direccion:Zona Industrial Santa Cruz\nMaturín Centro Industrial Ferro A-1', 12, 37)

          doc.setFontSize(35).text('Sistema Genérico', 10, 23)

          doc.setTextColor(255, 0, 0)
          doc.setFontSize(18).text(`Presupuesto: ${this.item.code}`, 120, 20)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 120, 27)
          doc.setFontSize(15).text('Vendedor:', 120, 35)

          doc.setFontSize(14).setTextColor(100, 100, 100).text(this.item.userName + ' ' + this.item.userLastName, 146, 35)

          doc.setFontSize(18).setTextColor(0, 0, 0).text('Datos Cliente', 10, 45)

          doc.setFontSize(13)

          doc.setTextColor(0, 0, 0).text('Nombres: ', 10, 55)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.name + ' ' + this.clientsSearch?.lastname || '', 32, 55)

          doc.setTextColor(0, 0, 0).text('C.I / RIF: ', 130, 55)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.ci, 150, 55)

          doc.setTextColor(0, 0, 0).text('Dirección: ', 10, 63)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.direction ? this.clientsSearch.direction.slice(0, 105) : '' || '', 32, 63)

          doc.setTextColor(0, 0, 0).text('Teléfono: ', 10, 75)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.phone || '', 30, 75)

          doc.setTextColor(0, 0, 0).text('Email: ', 80, 75)
          doc.setTextColor(100, 100, 100).text(this.clientsSearch?.email || '', 94, 75)

          doc.setTextColor(0, 0, 0)

          doc.setFontSize(16)
          doc.text('Productos', 90, 90)
          // // console.log(this.concessions)

          doc.autoTable({
            columns: [
              { title: 'Código', dataKey: 'productCode' },
              { title: 'Descripción', dataKey: 'productName' },
              { title: 'Rollos', dataKey: 'packingCant' },
              // { title: 'Unidad de empaque', dataKey: 'packingUnit' },
              { title: 'KG', dataKey: 'cant' },
              { title: 'Precio', dataKey: 'amountWithoutTax' },
              { title: 'Subtotal', dataKey: 'subtotal' },
            ],
            body: this.concessions.map(item => {
              // console.log('entre', item)
              return {
                ...item,
                cant: Math.abs(item.cantP),
                packingCant: Math.abs(item.packingCantP),
                subtotal: this.formatPrice(Math.abs(item.cantP * item.wTax)),
                amountWithoutTax: this.formatPrice(item.wTax),
              }
            }),
            margin: {
              left: 10,
              top: 95,
            },
          })

          // console.log('pasepase')

          doc.setLineWidth(0.5).line(0, 165, doc.internal.pageSize.width, 165)
          // doc.setLineWidth(0.5).line(0, 90, doc.internal.pageSize.width, 90)
          doc.setLineWidth(0.5).line(0, 80, doc.internal.pageSize.width, 80)

          doc.text('Métodos de pago', 83, 175)

          // console.log('pasepase')
          doc.autoTable({
            columns: [
              { title: 'Método de importe', dataKey: 'paymentMethods' },
              { title: 'Moneda de importe', dataKey: 'currency' },
              { title: 'Valor de la moneda de importe', dataKey: 'amountCurrencyReference' },
              { title: 'Monto', dataKey: 'amount' },
              { title: 'Monto en $', dataKey: 'totalAmountCurrencyReference' },
            ],
            body: this.salePayments.map(item => {
              // // // // // // // // // // // // // // // console.log('product', item)
              return {
                ...item,
                paymentMethods: item.paymentMethods.toUpperCase(),
                amountCurrencyReference: this.formatPrice(
                  item.amountCurrencyReference,
                  item.currencyId === 1 ? 'USD'
                  : item.currencyId === 2 ? 'EUR' : 'VES',
                ),
                amount: this.formatPrice(
                  item.amount,
                  item.currencyId === 1 ? 'USD'
                  : item.currencyId === 2 ? 'EUR' : 'VES',
                ),
                totalAmountCurrencyReference: this.formatPrice(item.totalAmountCurrencyReference),
              }
            }),
            margin: {
              left: 10,
              top: 1100,
              right: 10,
            },
            startY: 183,
          })
          // console.log('pasepase')

          if (this.saleSurplus.length > 0) {
            doc.autoTable({
              columns: [
                { title: 'Método de retorno', dataKey: 'paymentMethods' },
                { title: 'Moneda de retorno', dataKey: 'currency' },
                { title: 'Valor de la moneda de retorno', dataKey: 'amountCurrencyReference' },
                { title: 'Monto', dataKey: 'amount' },
                { title: 'Monto en $', dataKey: 'totalAmountCurrencyReference' },
              ],
              body: this.saleSurplus.map(item => {
                // // // // // // // // // // // // // // // console.log('product', item)
                return {
                  ...item,
                  paymentMethods: item.paymentMethods.toUpperCase(),
                  amountCurrencyReference: this.formatPrice(
                    item.amountCurrencyReference,
                    item.currencyId === 1 ? 'USD'
                    : item.currencyId === 2 ? 'EUR' : 'VES',
                  ),
                  amount: this.formatPrice(
                    item.amount,
                    item.currencyId === 1 ? 'USD'
                    : item.currencyId === 2 ? 'EUR' : 'VES',
                  ),
                  totalAmountCurrencyReference: this.formatPrice(item.totalAmountCurrencyReference),

                }
              }),
              margin: {
                left: 10,
                top: 1100,
                right: 10,

              },
              // startY: 210,
            })
          }

          doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 40, doc.internal.pageSize.width, doc.internal.pageSize.height - 40)
          doc.setFontSize(10)
          doc.text('Observaciones:', 10, doc.internal.pageSize.height - 35)

          doc.setFontSize(8)
          doc.text(this.item.description, 10, doc.internal.pageSize.height - 30)
          doc.setFontSize(12).setTextColor(100, 100, 100).text(`Subtotal:                ${this.formatPrice(this.item.totalAmountCurrencyReference)}`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 31)
          doc.setFontSize(12).setTextColor(100, 100, 100).text(`% de descuento:   ${this.item.discount}%`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 25)
          doc.setFontSize(12).setTextColor(100, 100, 100).text(`Monto descuento: ${this.formatPrice(((this.item.totalAmountCurrencyReference * this.item.discount) / 100))}`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 19)
          doc.setFontSize(15)
          doc.setTextColor(0, 0, 0)
          doc.text(`Total:               ${this.formatPrice(this.item.totalAmountCurrencyReference - ((this.item.totalAmountCurrencyReference * this.item.discount) / 100))}`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 10)

          doc.setLineWidth(0.5).line(75, doc.internal.pageSize.height - 17, 135, doc.internal.pageSize.height - 17)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma y Sello', 96, doc.internal.pageSize.height - 10)

          if (print) {
            doc.autoPrint()
            doc.output('dataurlnewwindow')
          } else {
            doc.save('presupuesto.pdf')
          }
        } else if (this.item.typeTransactionId === TYPE_TRANSACTION.concesion) {
          // doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
          doc.setFontSize(36).text('Sistema Genérico', 10, 23)
          doc.setTextColor(255, 0, 0)
          doc.setFontSize(18).text(`CÓDIGO: ${this.item.code}`, 130, 20)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)
          // doc.setTextColor(100, 100, 100)
          doc.setFontSize(13)
          doc.setTextColor(0, 0, 0).text('Solicitante:', 10, 35)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.name, 34, 35)
          doc.setTextColor(0, 0, 0).text('R.I.F.:', 10, 42)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.rif, 25, 42)
          doc.setTextColor(0, 0, 0).text('Dirección:', 10, 49)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.address, 33, 49)

          doc.setTextColor(0, 0, 0).text('Email:', 10, 60)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.email, 25, 60)
          doc.setTextColor(0, 0, 0).text('Teléfono1:', 10, 67)
          doc.setTextColor(100, 100, 100).text(this.businessWarehouses.phone1, 33, 67)
          if (this.businessWarehouses.phone2) {
            doc.setTextColor(0, 0, 0).text('Teléfono2:', 100, 67)
            doc.setTextColor(100, 100, 100).text(this.businessWarehouses.phone2, 124, 67)
          }

          doc.setFontSize(18).setTextColor(0, 0, 0).text('Nota de entrega', 82, 90)
          // doc.text('Nota de entrega', 82, 75)

          doc.autoTable({
            columns: [
              { title: 'Código', dataKey: 'productCode' },
              { title: 'Descripción', dataKey: 'productName' },
              { title: 'Rollos', dataKey: 'packingCant' },
              // { title: 'Unidad de empaque', dataKey: 'packingUnit' },
              { title: 'KG', dataKey: 'cant' },
              { title: 'Precio Unitario', dataKey: 'amount_total' },
              { title: 'Precio Total', dataKey: 'total' },
            ],
            body: this.concessions.map(item => {
              return {
                ...item,
                cant: Math.abs(item.cantP),
                packingCant: Math.abs(item.packingCantP),
                total: this.formatPrice(Math.abs(item.cantP * item.wTax)),
                amount_total: this.formatPrice(item.wTax),
              }
            }),
            margin: {
              left: 10,
              top: 100,
            },
          })

          // // // // // // console.log(doc.internal.pageSize.width)

          doc.setLineWidth(0.5).line(10, doc.internal.pageSize.height - 60, 70, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Nombre de quien recibe', 20, doc.internal.pageSize.height - 53)
          doc.setLineWidth(0.5).line(75, doc.internal.pageSize.height - 60, 140, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('C.I. de quien recibe', 92, doc.internal.pageSize.height - 53)
          doc.setLineWidth(0.5).line(145, doc.internal.pageSize.height - 60, 205, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma de quien recibe', 155, doc.internal.pageSize.height - 53)

          doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 30, doc.internal.pageSize.width, doc.internal.pageSize.height - 30)
          doc.setFontSize(10)
          doc.setTextColor(0, 0, 0)
          doc.text('Observaciones:', 10, doc.internal.pageSize.height - 25)
          doc.setFontSize(8)
          doc.text(this.item.description, 10, doc.internal.pageSize.height - 20)
          doc.setFontSize(15)
          doc.text(`Total: ${this.formatPrice(this.item.totalAmountCurrencyReference)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 10)

          // doc.autoPrint()
          // doc.output('dataurlnewwindow')
          if (print) {
            doc.autoPrint()
            doc.output('dataurlnewwindow')
          } else {
            doc.save('nota-de-entrega.pdf')
          }
        } else if (this.item.typeTransactionId === TYPE_TRANSACTION.salida) {
          doc.setFontSize(38).text('Sistema Genérico', 10, 23)
          doc.setTextColor(255, 0, 0)
          doc.setFontSize(18).text(`CÓDIGO: ${this.item.code}`, 130, 20)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)

          doc.setTextColor(0, 0, 0)
          doc.setFontSize(18).setTextColor(0, 0, 0).text('Nota de salida', 82, 60)

          doc.autoTable({
            columns: [
              { title: 'Codigo del producto', dataKey: 'productCode' },
              { title: 'Nombre del producto', dataKey: 'productName' },
              { title: 'Rollos', dataKey: 'packingCant' },
              // { title: 'Unidad de empaque', dataKey: 'packingUnit' },
              { title: 'KG', dataKey: 'cant' },
              { title: 'Precio Unitario', dataKey: 'amount_total' },
              { title: 'Precio Total', dataKey: 'total' },
            ],
            body: this.concessions.map(item => {
              return {
                ...item,
                cant: Math.abs(item.cantP),
                packingCant: Math.abs(item.packingCantP),
                total: this.formatPrice(Math.abs(item.cantP * item.wTax)),
                amount_total: this.formatPrice(item.wTax),
              }
            }),
            margin: {
              left: 10,
              top: 65,
            },
          })
          doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 30, doc.internal.pageSize.width, doc.internal.pageSize.height - 30)
          doc.setFontSize(10)
          doc.text('Observaciones:', 10, doc.internal.pageSize.height - 25)
          doc.setFontSize(8)
          doc.text(this.item.description, 10, doc.internal.pageSize.height - 20)
          doc.setFontSize(15)
          doc.text(`Total: ${this.formatPrice(this.item.totalAmountCurrencyReference)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 10)

          if (print) {
            doc.autoPrint()
            doc.output('dataurlnewwindow')
          } else {
            doc.save('presupuesto.pdf')
          }
        } else {
          doc.setFontSize(38).text('Sistema Genérico', 10, 23)
          doc.setTextColor(255, 0, 0)
          doc.setFontSize(18).text(`CÓDIGO: ${this.item.code}`, 130, 20)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)

          doc.setTextColor(0, 0, 0)
          doc.setFontSize(18).setTextColor(0, 0, 0).text(`Recepción de devolución de la sucursal ${this.item.relatedWarehouse}`, 45, 45)

          doc.autoTable({
            headStyles: { fontSize: 14 },
            styles: { fontSize: 12, halign: 'center' },
            // columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } },
            columns: [
              { title: 'Tipo de tela', dataKey: 'productName' },
              { title: 'Rollos', dataKey: 'packingCant' },
              // { title: 'Unidad de empaque', dataKey: 'packingUnit' },
              { title: 'KG', dataKey: 'cant' },
              { title: 'Motivo de la devolución', dataKey: 'motive' },
            ],
            body: this.concessions.map(item => {
              return {
                ...item,
                packingCant: Math.abs(item.packingCantP),
                cant: Math.abs(item.cantP),
                motive: this.item.description,
              }
            }),
            margin: {
              left: 10,
              top: 65,
            },
          })

          doc.setLineWidth(0.5).line(10, doc.internal.pageSize.height - 60, 70, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma de entrega', 25, doc.internal.pageSize.height - 53)

          doc.setLineWidth(0.5).line(145, doc.internal.pageSize.height - 60, 205, doc.internal.pageSize.height - 60)
          doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma recibido', 164, doc.internal.pageSize.height - 53)

          if (print) {
            doc.autoPrint()
            doc.output('dataurlnewwindow')
          } else {
            doc.save('nota-de-devolucion.pdf')
          }
        }
      },

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },

      async submit (e) {
        e.preventDefault()
        const total = this.total
        if (this.operation === TYPE_TRANSACTION.salida) {
          const submit = {
            ...this.data,
            products: [{ ...this.productSelected, cant: this.cant, packingCant: this.packingCant }],
            typeTransactionId: TYPE_TRANSACTION.salida,
            userId: this.user.id,
            warehouseId: this.actionWarehouse,
            relatedWarehouseId: null,
            total: total,
            code: this.actionEnvironmentData.code,
          }

          await this.createWarehouseConcessions(submit)

          this.afterCreate()
        } else {
          if (this.businessId !== null && this.disabledSave === false) {
            const submit = {
              ...this.data,
              products: [{ ...this.productSelected, cant: this.cant, packingCant: this.packingCant }],
              typeTransactionId: this.operation,
              userId: this.user.id,
              warehouseId: this.actionWarehouse,
              total: total,
              approvate: true,
              code: this.actionEnvironmentData.code,
              sucursalId: this.actionWarehouse,
            }

            await this.createWarehouseConcessions(submit)

            this.afterCreate()
          }
        }
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
