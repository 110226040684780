<template>
  <div>
    <br>
    <!-- {{ formatDate(getFirstDayOfWeek) }}
    {{ formatDate(getLastDayOfWeek) }} -->
    <v-row
      justify="center"
      justify-md="end"
    >
      <v-col
        cols="3"
        md="1"
      >
        <v-btn
          color="orange"
          fab
          text
          large
          @click="openedAddDialog = !openedAddDialog"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="3"
        md="1"
      >
        <v-btn
          :color="showAdd ? 'red' : 'orange'"
          fab
          large
          @click="$emit('show')"
        >
          <v-icon v-if="showAdd">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <v-autocomplete
          v-model="relatedWarehouseId"
          label="Tiendas"
          :loading="fetchingStoreWarehouses"
          :disabled="fetchingStoreWarehouses"
          :items="getStoreWarhouses"
          item-text="business"
          item-value="warehouseId"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <v-autocomplete
          v-model="approved"
          label="Solicitudes"
          :items="status"
          no-data-text="No hay coincidencia"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <v-dialog
          ref="dialog"
          v-model="modalS"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(start)"
              label="Fecha de inicio"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="start"
            color="light-blue darken-1"
            scrollable
            locale="es-ve"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="modalS = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modalS = false"
            >
              <!-- @click="$refs.dialog.save(start)" -->
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <v-dialog
          ref="dialog"
          v-model="modalE"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(end)"
              label="Fecha final"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="end"
            scrollable
            color="light-blue darken-1"
            locale="es-ve"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="modalE = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modalE = false"
            >
              <!-- @click="$refs.dialog.save(end)" -->
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <!-- <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-autocomplete
          v-model="week"
          label="Semana #"
          :items="weeks"
          item-text="name"
          item-value="value"
          no-data-text="No hay coincidencia"
          :hint="`Inicio: ${formatDate(getFirstDayOfWeek)} - Fin: ${formatDate(getLastDayOfWeek)}`"
          persistent-hint
        />
      </v-col> -->

      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <v-autocomplete
          v-model="type"
          label="Tipo de operacion"
          :items="typeFilter"
          item-text="name"
          item-value="value"
          no-data-text="No hay coincidencia"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <v-autocomplete
          v-model="code"
          label="Códigos"
          :items="codes"
          item-text="name"
          item-value="value"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <!-- <v-col cols="2" /> -->
    </v-row>

    <v-row
      v-if="type !== 0 && approved === 'SIN APROBAR'"
      justify="end"
    >
      <v-col cols="2">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="fetchingWarehouseConcessions"
              color="green"
              class="transparent-button-icon"
              icon
              v-bind="attrs"
              v-on="on"
              @click="aprobeAll"
            >
              <v-icon size="30">
                mdi-check-all
              </v-icon>
            </v-btn>
          </template>
          <span>Presione aprobar masivamente todas las transacciones seleccionadas por el filtro</span>
        </v-tooltip>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="fetchingWarehouseConcessions"
              color="red"
              class="transparent-button-icon"
              icon
              v-bind="attrs"
              v-on="on"
              @click="noAprobeAll"
            >
              <v-icon size="30">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Presione denegar masivamente todas las transacciones seleccionadas por el filtro</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <add-concessions v-model="openedAddDialog" />
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import AddConcessions from './AddConcessions.vue'
  import { DateTime } from 'luxon'
  import { TYPE_TRANSACTION } from '../../../../const'

  export default {
    name: 'ConcessionsTableTop',

    components: {
      AddConcessions,
    },

    props: {
      // type: {
      //   type: String,
      //   default: () => '',
      // },

      concessions: {
        type: Array,
        default: () => [],
      },

      showAdd: {
        type: Boolean,
        default: () => false,
      },

      typeFilter: {
        type: Array,
        default: () => [],
      },
    },

    data () {
      return {
        code: 'TODOS',
        start: '',
        end: '',
        relatedWarehouseId: '',
        approved: 'TODAS',
        modalS: false,
        modalE: false,
        openedAddDialog: false,
        status: ['TODAS', 'APROBADAS', 'SIN APROBAR'],
        initialData: true,
        type: 0,
        weeks: [],
        week: 0,
      }
    },

    computed: {
      ...mapState([
        'user',
        'fetchingStoreWarehouses',
        'storeWarehouses',
        'actionWarehouse',
        'warehousesConcessions',
        'fetchingWarehouseConcessions',
      ]),

      codes () {
        const res = []
        if (this.type) {
          if (this.warehousesConcessions.length > 0) {
            const auxArry = [
              '',
              TYPE_TRANSACTION.venta_por_concesion,
              TYPE_TRANSACTION.concesion,
              TYPE_TRANSACTION.devolucion_por_concesion,
              TYPE_TRANSACTION.salida,
            ]
            this.warehousesConcessions.filter(item => item.typeTransactionId === auxArry[this.type]).forEach(item => {
              // // console.log('item', item.code)
              if (res.indexOf(item.code) < 0) {
                res.push(item.code)
              }
            })
          }
        } else {
          if (this.warehousesConcessions.length > 0) {
            this.warehousesConcessions.forEach(item => {
              // // console.log('item', item.code)
              if (res.indexOf(item.code) < 0) {
                res.push(item.code)
              }
            })
          }
        }
        // // // console.log('Inputs', this.warhouseInputs)
        // // console.log('res', res)
        return ['TODOS', ...res]
      },

      getFirstDayOfWeek () {
        const dt = DateTime.fromObject({
          weekYear: new Date().getFullYear(),
          weekNumber: this.week,
        })

        const dateFromStr = dt.startOf('week')
        return dateFromStr.toFormat('yyyy-MM-dd')
      },

      getLastDayOfWeek () {
        const dt = DateTime.fromObject({
          weekYear: new Date().getFullYear(),
          weekNumber: this.week,
        })

        const dateFromStr = dt.endOf('week')
        return dateFromStr.toFormat('yyyy-MM-dd')
      },

      getStoreWarhouses () {
        return [{ business: 'TODAS', warehouseId: '' }, ...this.storeWarehouses]
      },

      form () {
        return {
          id: this.actionWarehouse || this.$route.params.id,
          start: this.start,
          end: this.end,
          relatedWarehouseId: this.relatedWarehouseId,
          approved: this.approved === 'TODAS' ? '' : this.approved === 'APROBADAS' ? 1 : 0,
          // businessId: 2,
        }
      },

    },

    watch: {
      async form (v) {
        if (!this.initialData) {
          this.changeParams(v)
          await this.fetchWarehouseConcessions(v)
        }

        this.type = 0
        this.code = 'TODOS'
      },

      week (v) {
        this.start = this.getFirstDayOfWeek
        this.end = this.getLastDayOfWeek
      },

      type (v) {
        this.$emit('changeType', v)
        this.code = 'TODOS'
      },

      code (v) {
        this.$emit('changeCode', v)
      },
    },

    created () {
      this.end = DateTime.fromJSDate(this.nextWeek()).toFormat('yyyy-MM-dd')
      this.start = DateTime.fromJSDate(this.lastWeek()).toFormat('yyyy-MM-dd')
      this.initialData = false
      this.week = this.getThisWeek()

      for (let i = 1; i < 53; i++) {
        this.weeks.push({ name: `Semana ${i}`, value: i })
      }
    },

    methods: {
      ...mapActions([
        'fetchWarehouseConcessions',
        'aproveWarhouseConcessionsByCode',
        'deleteWarhouseConcessionsByCode',
        'fetchWarehouseConcessions',
        'fetchWarehouseInventory',
        'aproveWarhouseConcessionsById',
        'deleteWarhouseConcessionsById',
      ]),

      getThisWeek () {
        return DateTime.now().weekNumber
      },

      async aprobeAll () {
        for await (const item of this.concessions) {
          // await this.makeRequestByItem(item)
          // // // // // console.log(item.code)

          const form = {
            code: item.code,
            approvedByUserId: this.user.id,
          }
          await this.aproveWarhouseConcessionsByCode(form)
          await this.fetchWarehouseConcessions(this.form)
        }
      },

      async noAprobeAll () {
        for await (const item of this.concessions) {
          const form = {
            code: item.code,
            approvedByUserId: this.user.id,
          }
          await this.deleteWarhouseConcessionsByCode(form)
          await this.fetchWarehouseConcessions(this.form)
        }
      },

      ...mapMutations({
        changeParams: 'CHANGE_PARAMS',
      }),

      formatDate (dateProp) {
        return DateTime
          .fromFormat(dateProp, 'yyyy-MM-dd')
          .toFormat('dd/MM/yyyy')
      },

      lastWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
      },

      nextWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
      },
    },

  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
