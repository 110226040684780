<template>
  <v-dialog
    v-model="openedDialog"
    max-width="1100"
  >
    <v-card>
      <v-card-title>
        Realiza una concesión de produtos
      </v-card-title>
      <add-concessions-form @close="openedDialog = false" />
    </v-card>
  </v-dialog>
</template>

<script>
  import AddConcessionsForm from './AddConcessionsForm.vue'

  export default {
    name: 'AddConcessions',
    components: { AddConcessionsForm },
    props: {
      value: {
        type: Boolean,
        default () {
          return false
        },
      },
    },
    computed: {
      openedDialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
  }
</script>
