<template>
  <concessions-table />
</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex'
  import ConcessionsTable from '../components/warehouses-concessions/ConcessionsTable.vue'
  export default {
    name: 'WarehousesConcessions',
    components: {
      ConcessionsTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness', 'actionWarehouse']),
    },

    async created () {
      await this.getStoreWarehouses()
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness, businessId: this.actionWarehouse })
      await this.fetchWarehouseInventory({ id: Number(this.$route.params.id) || this.actionWarehouse })
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('warehouse')
      // await this.fetchWarehouseOutputs({ id: this.actionWarehouse })
      // await this.fetchWarehouseConcessions({ id: this.actionWarehouse })
      // await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductClassification({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductCategory({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapMutations({
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActions: 'SET_ACTIONS_WAREHOUSE',
      }),
      ...mapActions([
        'fetchWarehouseOutputs',
        'getStoreWarehouses',
        'fetchWarehouseInventory',
        'fetchWarehouseConcessions',
        // 'fetchProductLine',
        // 'fetchProductClassification',
        // 'fetchProductCategory',
        // 'fetchProductType',
        'fetchProducts',
      ]),
    },
  }
</script>
