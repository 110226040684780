var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.item.approved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"disabled":_vm.fetchingWarehouseConcessions,"color":"green","icon":""},on:{"click":function($event){_vm.modalA = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check-bold ")])],1)]}}],null,false,1456502793)},[_c('span',[_vm._v("Presione para aceptar la transacción")])]):_vm._e(),(!_vm.item.approved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"disabled":_vm.fetchingWarehouseConcessions,"color":"red","icon":""},on:{"click":function($event){_vm.modalE = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,false,1231925330)},[_c('span',[_vm._v("Presione para denegar la transacción")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"color":"light-blue darken-1","icon":""},on:{"click":function($event){_vm.openedEditDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-magnify-plus-outline ")])],1)]}}])},[_c('span',[_vm._v("Presione para ver el detalle de la operación")])]),(_vm.item.approved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"disabled":_vm.fetchingProducts,"color":"primary","icon":""},on:{"click":_vm.downloadPdf}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-download ")])],1)]}}],null,false,855230690)},[_c('span',[_vm._v("Presione para descargar el documento")])]):_vm._e(),(_vm.item.approved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"disabled":_vm.fetchingProducts,"color":"secondary","icon":""},on:{"click":_vm.printPdf}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-printer ")])],1)]}}],null,false,347891267)},[_c('span',[_vm._v("Presione para imprimir el documento")])]):_vm._e(),_c('concessions-detail-dialog',{attrs:{"id":_vm.item},model:{value:(_vm.openedEditDialog),callback:function ($$v) {_vm.openedEditDialog=$$v},expression:"openedEditDialog"}}),_c('v-dialog',{attrs:{"max-width":"530"},model:{value:(_vm.modalA),callback:function ($$v) {_vm.modalA=$$v},expression:"modalA"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h3"},[_vm._v(" ¿Seguro de querer aceptar esta operación? ")]),_c('v-card-text',{staticClass:"text-center grey--text"},[_vm._v(" Esta accion creara un registro permanente en la aplicación, ¿Desea continuar? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.modalA = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.fetchingWarehouseConcessions},on:{"click":_vm.approve}},[_vm._v(" Aceptar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"490"},model:{value:(_vm.modalE),callback:function ($$v) {_vm.modalE=$$v},expression:"modalE"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h3"},[_vm._v(" ¿Seguro de querer eliminar esta solicitud? ")]),_c('v-card-text',{staticClass:"text-center grey--text"},[_vm._v(" Esta accion creara un registro permanente en la aplicación, ¿Desea continuar? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.modalE = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.fetchingWarehouseConcessions},on:{"click":_vm.deleteTransaction}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }